import Home from './components/pages/Home'
import Impacts from './components/pages/Impacts'
import ImpactArticle from './components/pages/ImpactArticle'
import Submissions from './components/pages/Submissions'
import Documents from './components/pages/Documents'
import FAQs from './components/pages/FAQs'
import Details from './components/pages/Details'
import Blog from './components/pages/Blog'
import NewsArticle from './components/pages/NewsArticle'
import Contact from './components/pages/Contact'
import Error500 from './components/pages/Error500'
import Error404 from './components/pages/Error404'


let routes = [
    {   
        path: '/', // ensure home is first item
        name: 'home',
        component: Home,
        meta: { title: 'Home' }
    },
    {
        path: '/impacts',
        name: 'impacts',
        component: Impacts,
        meta: { title: 'Impacts' },
    },
    {
        path: '/impacts/:slug',
        name: 'impactArticle',
        component: ImpactArticle,
    },
    // {
    //     path: '/submissions',
    //     name: 'submissions',
    //     component: Submissions,
    //     meta: { title: 'Submissions' },
    // },
    {
        path: '/documents',
        name: 'documents',
        component: Documents,
        meta: { title: 'Documents' },
    },
    {
        path: '/faqs',
        name: 'faqs',
        component: FAQs,
        meta: { title: 'Documents' },
    },
    {
        path: '/details',
        name: 'details',
        component: Details,
        meta: { title: 'Details' },
    },
    // {
    //     path: '/blog',
    //     name: 'blog',
    //     component: Blog,
    //     meta: { title: 'Blog' },
    // },
    // {
    //     path: '/blog/:slug',
    //     name: 'newsArticle',
    //     component: NewsArticle,
    // },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: { title: 'Contact' },
    },
    { 
        path: "/error", 
        name: 'errorServer',
        component: Error500,
    },
    { 
        path: "*", 
        name: 'errorContent',
        component: Error404,
    }
]

export default {
    mode: 'history',
    routes: routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
}