<template>
  <div class="content-block">
    <div class="content-block-inner container">
      <div class="col inset">
        <slot></slot>
      </div>
      <div v-if="hasSecondary" class="col" :class="{'inset' : insetSecondary}">
        <slot name="secondary"></slot>
      </div>
      <slot v-if="hasMisc" name="misc"></slot>
    </div>
  </div>
</template>


<script>
  export default {
    props: {
      insetSecondary: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
      }
    },
    computed: {
      hasSecondary() {
        return !!this.$slots.secondary
      },
      hasMisc() {
        return !!this.$slots.misc
      }
    },
    methods: {
    },
  }
</script>