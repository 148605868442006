<template>
  <div id="news-article" v-if="loaded">
    
    <div class="hero-image is-cover is-article">
      <image-helper :src="'Blog-header'"></image-helper>
    </div>

    <content-block class="white article has-img" :inset-secondary="true">
      <span>{{entry.publishDate}}</span>
      <h2>{{entry.titleA}}</h2>
      <template v-slot:secondary>
        <wysiwyg :data="entry.wysiwygContent"></wysiwyg>
      </template>
    </content-block>

  </div>
</template>


<script>
  import HeroImage from "~/components/HeroImage.vue";
  import ContentBlock from "~/components/ContentBlock.vue";
  import Wysiwyg from "~/components/Wysiwyg.vue";
  import SignUpForm from "~/components/SignUpForm.vue";
  import ImageHelper from "~/components/ImageHelper.vue";
  import gql from 'graphql-tag'

  export default {
    components: { HeroImage, ContentBlock, Wysiwyg, SignUpForm, ImageHelper },
    data(){
      return {
        entry: Object,
      }
    },
    apollo: {
      page: {
        query: gql`query variables($slug: [String!]){
          page: entry(slug: $slug){
                  title,
                  slug, sectionHandle
                  publishDate: postDate @formatDateTime (format: "l j F", timezone: "Pacific/Auckland")
                  ...on articles_articles_Entry{
                    heroImage{
                      url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                    titleA
                    wysiwygContent 
                  }
                }
              }
          `,
          variables() {      
            return {slug: this.$route.params.slug}
          },
        result ({ data, loading, networkStatus }) {
          if (typeof(data) !== 'undefined'){
            if (data.page !== null){ 
              console.log(data.page)
              this.entry = data.page
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'errorContent' })
            }
          }
          else{
            this.$router.push({ name: 'errorServer' })
          }
        }
      },
    },
    computed: {
    }
  }
</script>