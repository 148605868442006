
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueScrollTo from 'vue-scrollto'
import VueGtag from 'vue-gtag'
//import VueAnalytics from 'vue-analytics';
import Meta from 'vue-meta'
// import * as VueGoogleMaps from 'vue2-google-maps'
import Store from './store.js'
import Router from './router.js'
import Helpers from './helpers.js'
import {InlineSvgPlugin} from 'vue-inline-svg';
import VueObserveVisibility from 'vue-observe-visibility'
import VScrollLock from 'v-scroll-lock'
import CraftImageHelper from './components/CraftImageHelper.vue'
import App from './components/App.vue'
import Stringify from 'vue-stringify'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import schema from './schema.json'
import VueApollo from 'vue-apollo'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueAxios, axios);
Vue.use(VueScrollTo)
Vue.use(Helpers)
Vue.use(Meta)
Vue.use(InlineSvgPlugin);
Vue.use(VueObserveVisibility)
Vue.use(VScrollLock)
Vue.use(VueApollo)

Vue.component('craft-image-helper', CraftImageHelper)

Vue.use(VueReCaptcha, { siteKey: '6LdNwtMZAAAAAGzdDzdh5rt4c-vKjdpas9VpJ3Wt',loaderOptions: {
    autoHideBadge: true
  } })



axios.defaults.baseURL = process.env.BASE_URL;
let m = document.location.href.match(/\btoken=([^&]+)/);
let token = m ? m[1] : '';
// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here    
  uri: `${process.env.BASE_URL}/api?token=${token}`
})

// Retrieve schema map
const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: schema
})

// Cache implementation
const cache = new InMemoryCache({fragmentMatcher})

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
})


// Configuration VueAnalytics
// Vue.use(VueAnalytics, {
//   id: 'UA-122647541'
// });

// Vue.use(VueGoogleMaps, {
// 	load: {
// 		key: '',
// 		libraries: 'geometry',
// 	},
// })

const router = new VueRouter(Router)
const store = new Vuex.Store(Store);


Vue.use(VueGtag, {
  config: { id: "G-K27J5RVM77" }
}, router);


router.beforeEach((to, from, next) => {

  store.commit('setCraftLoaded', false)
  store.commit('setCraftPageData', null)

  next();
});


Vue.mixin({
  computed: {
   craftLoaded: {
     get() {
       return this.$store.state.craftLoaded
     }
    },
    // settingsLoaded: {
    //  get() {
    //    return this.$store.state.settingsLoaded
    //  }
    // },
    loaded() {
     return this.craftLoaded
    },
  }
})


new Vue({
	router,
	store,
	apolloProvider,
	render: createElement => createElement(App)
}).$mount('#app');