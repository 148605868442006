<template>
  <div id="footer">
    <content-block v-if="!isSubmissions" class="yellow" :inset-secondary="true" id="sign-up" >
      <h2>Sign up for our email update</h2>
      <template v-slot:secondary>
        <sign-up-form class="dark"></sign-up-form>
      </template>
    </content-block>

    <footer>
      <div class="container">
        <span class="inset"></span>
        <img src="/assets/img/northport.svg">
      </div>
    </footer>
  </div>
  
</template>


<script>
  import ContentBlock from "~/components/ContentBlock.vue";
  import SignUpForm from "~/components/SignUpForm.vue";

  export default {
    components: {ContentBlock, SignUpForm },
    data(){
      return {
      }
    },
    computed: {
      isSubmissions() {
        return this.$route.name === 'submissions'
      }
    }
  }
</script>