<template>
  <div id="contact" v-if="loaded">

    <hero-image :src="entry.heroImage"></hero-image>
    
    <content-block class="white">
      <h2>{{entry.titleA}}</h2>
      <wysiwyg :data="entry.blurb"></wysiwyg>
    </content-block>

  </div>
</template>


<script>
  import HeroImage from "~/components/HeroImage.vue";
  import ContentBlock from "~/components/ContentBlock.vue";
  import Wysiwyg from "~/components/Wysiwyg.vue";
  import EnquiryForm from '~/components/EnquiryForm'
  import gql from 'graphql-tag'

  export default {
    components: { HeroImage, ContentBlock, EnquiryForm, Wysiwyg },
    data(){
      return {
        entry: Object
      }
    },
    apollo: {
      page: {
        query: gql`query{
          page: entry(section: "contact"){
                  title,
                  slug, sectionHandle
                  ...on contact_contact_Entry{
                    heroImage{
                      url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                    titleA: contactTitle
                    blurb: contactBlurb
                  }
                }          
              }
          `,
        result ({ data, loading, networkStatus }) {
          if (typeof(data) !== 'undefined'){
            if (data.page !== null){ 
              // console.log(data.page)
              this.entry = data.page
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'errorContent' })
            }
          }
          else{
            this.$router.push({ name: 'errorServer' })
          }
        }
      },
    },
    computed: {
    }
  }
</script>