<template>
  <div id="impacts" v-if="loaded">   

    <hero-image :src="entry.heroImage"></hero-image>

    <content-block class="blue has-overlay">
      <h2>{{entry.titleA}}</h2>
      <wysiwyg :data="entry.blurbA"></wysiwyg>
    </content-block>
    
    <content-block class="white" :inset-secondary="true" v-if="entry.titleB || impactPages.length">
      <h2 v-if="entry.titleB">{{entry.titleB}}</h2>
      <p>Click on the links below to find out more. The full reports into these and other areas of our proposed development can be found in and downloaded from the <router-link :to="{name: 'documents'}" class="quickfix">Documents section</router-link> of this website.</p>
      <template v-if="impactPages.length" v-slot:secondary>
        <impacts-grid :impacts="impactPages"></impacts-grid>
      </template>
    </content-block>

    <content-block class="grey" v-if="entry.titleC || entry.blurbC">
      <h2 v-if="entry.titleC">{{entry.titleC}}</h2>
      <wysiwyg v-if="entry.blurbC" :data="entry.blurbC"></wysiwyg>
    </content-block>
  </div>
</template>


<script>
  import HeroImage from "~/components/HeroImage.vue";
  import ContentBlock from "~/components/ContentBlock.vue";
  import Wysiwyg from "~/components/Wysiwyg.vue";
  import ImpactsGrid from "~/components/ImpactsGrid.vue";
  import gql from 'graphql-tag'

  export default {
    components: { HeroImage, ContentBlock, Wysiwyg, ImpactsGrid },
    data(){
      return {
        entry: Object,
        impactPages: Object
      }
    },
    apollo: {
      page: {
        query: gql`query{
          page: entry(section: "impacts"){
                  title,
                  slug, sectionHandle
                  ...on impacts_impacts_Entry{
                    heroImage{
                      url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                    titleA: impactsTitleA
                    blurbA: impactsBlurbA
                    titleB: impactsTitleB
                    titleC: impactsTitleC
                    blurbC: impactsBlurbC
                  }
                }
                impacts: entries(section: "impactPages"){
                  id, title, slug, sectionHandle ...on impactPages_impactPages_Entry{
                    backgroundColor
                    textColor
                    docLink: impactDoc{
                      url
                    }
                    heroImage{
                      url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                  }
                }
              }
          `,
        result ({ data, loading, networkStatus }) {
          if (typeof(data) !== 'undefined'){
            if (data.page !== null){ 
              // console.log(data.page)
              // console.log(data.impacts)
              this.entry = data.page
              this.impactPages = data.impacts
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'errorContent' })
            }
          }
          else{
            this.$router.push({ name: 'errorServer' })
          }
        }
      },
    },
  }
</script>

<style scoped>
  
</style>