<template>
  <div class="article-grid">
    <router-link 
      class="teaser" 
      v-for="(article,index) in articles"
      :key="index"
      :to="{name: 'newsArticle', params: {slug: article.slug}}"
      :class="{'no-img' : !article.heroImage.length}">
      <div class="is-cover three-two">
        <span>{{article.publishDate}}</span>
        <craft-image-helper v-if="article.heroImage.length" :src="article.heroImage"></craft-image-helper>
        <img v-else src="/assets/img/northport-icon.svg">
      </div>
      <div class="title">
        {{article.title}}
      </div>
    </router-link>
  </div>
  
</template>


<script>
  export default {
    props: {
      articles: {
        type: Array,
        required: true
      }
    },
    data(){
      return {
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>
