<template>
  <div id="blog" v-if="data.articles.length">
    
    <hero-image :src="entry.heroImage"></hero-image>

    <content-block class="grey">
      <h2>{{entry.titleA}}</h2>
      <template v-slot:secondary v-if="data.articles.length">
        <article-grid :articles="data.articles"></article-grid>
        <div v-if="loadedArticles < totalArticles" @click="loadMore()" class="load-more">Show older posts</div>
      </template>
    </content-block>

  </div>
</template>

<script>
  import HeroImage from "~/components/HeroImage.vue";
  import ContentBlock from "~/components/ContentBlock.vue";
  import ArticleGrid from "~/components/ArticleGrid.vue";
  import gql from 'graphql-tag'

  export default {
    components: { HeroImage, ContentBlock, ArticleGrid },
    data(){
      return {
        batch: 12,
        total: 0,
        skipQuery: false,
        entry: Object,
        data:{
          articles: [],
          articlesLoaded: false,
        }
      }
    },
    apollo: {
      page: {
        query: gql`query {
          page: entry(section: "news"){
                title,
                slug, sectionHandle
                ...on news_news_Entry{
                  heroImage{
                    url,
                        title,
                        width,
                        height,
                        focalPoint,
                        mimeType,
                        s400: url @transform(width: 400),
                        s800: url @transform(width: 800),
                        s1200: url @transform(width: 1200),
                        s1600: url @transform(width: 1600),
                        s2000: url @transform(width: 2000),
                        s2400: url @transform(width: 2400),
                        s2800: url @transform(width: 2800),
                        s3200: url @transform(width: 3200)
                  }
                  titleA
                }
              }
            }
          `,
        result ({ data, loading, networkStatus }) {
          if (typeof(data) !== 'undefined'){
            if (data.page !== null){ 
              // console.log(data.page)
              this.entry = data.page
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'errorContent' })
            }
          }
          else{
            this.$router.push({ name: 'errorServer' })
          }
        },
      },
      newsArticles: {
          query: gql`query variables($limit: Int!,$offset: Int!)  {
              newsArticles: entries(section: "articles", orderBy: "postDate DESC", limit: $limit, offset: $offset){
                  id, title, slug, sectionHandle
                  publishDate: postDate @formatDateTime (format: "l j F", timezone: "Pacific/Auckland")
                  ...on articles_articles_Entry{
                    heroImage{
                      url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                  }
                }
              total: entryCount (section: "articles")
            }
          `,
          fetchPolicy: 'no-cache',
        variables() {
          let offset = 0

          if (this.data.articlesLoaded){
            offset = this.data.articles.length
          }
          else{
            offset = 0
          }

          return {
            limit: this.batch,
            offset: offset
          }
        },
        skip() {
          return this.skipQuery
        },
        result ({ data, loading, networkStatus }) {
          if(data.newsArticles !== null) {
            if (!this.data.articlesLoaded){
              this.data.articles = data.newsArticles
              this.data.articlesLoaded = true
              this.total = data.total
            }
            else{
              this.data.articles.push(...data.newsArticles)
            }
            this.$apollo.queries.newsArticles.skip = true
          }
        }
      },
    },
    computed: {
      loadedArticles(){
        return this.data.articles.length
      },
      totalArticles(){
        return this.total-1
      },
    },
    methods: {
      loadMore(){
        this.$apollo.queries.newsArticles.skip = false
        this.$apollo.queries.newsArticles.refetch()
      }
    },
    created(){
      this.$apollo.queries.newsArticles.skip = false
      this.$apollo.queries.newsArticles.refetch()
    }
  }
</script>