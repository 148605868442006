<template>
  <div id="error404">
    <div class="container">
      <h2>Error.</h2>
      <h3>Sorry, we cant find the page you are looking for – here’s some links that may be helpful.</h3>
      <ul>
        <li><router-link :to="{name: 'home'}">Home</router-link></li>
        <li><router-link :to="{name: 'impacts'}">Impacts</router-link></li>
        <li><router-link :to="{name: 'faqs'}">Ask us a question</router-link></li>
        <li><router-link :to="{name: 'documents'}">Documents</router-link></li>
        <li><router-link :to="{name: 'contact'}">Contact / Visit</router-link></li>
      </ul>
    </div>
  </div>
</template>


<script>

  export default {
    data(){
      return {
      }
    },
    computed: {

    }
  }
</script>