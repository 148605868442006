<template>
  <div id="faqs" v-if="loaded">

    <hero-image :src="entry.heroImage"></hero-image>

    <content-block class="white">
      <h2>{{entry.titleA}}</h2>
      <wysiwyg :data="entry.blurbA"></wysiwyg>
    </content-block>

    <content-block class="grey" :inset-secondary="!isXs" v-if="entry.titleB || entry.questionsAnswers.length">
      <h2 v-if="entry.titleB">{{entry.titleB}}</h2>
      <template v-slot:secondary v-if="entry.questionsAnswers.length">
        <template v-for="(section,groupIndex) in entry.questionsAnswers">
          <div class="accordion-group-title yellow">
            {{section.sectionTitle}} <span @click="expandAll(groupIndex)">Show / Hide All</span>
          </div>
          <accordion-item v-for="(faq,index) in section.SectionQuestions" class="yellow" :groupUid="uids[groupIndex]">
            <template v-slot:title>
              {{faq.question}}
            </template>
            <template v-slot:content>
              <wysiwyg :data="faq.answer" :lightbox="true"></wysiwyg>
            </template>
          </accordion-item>
        </template>
      </template>
    </content-block>


    <content-block class="blue" v-if="entry.formTitle || entry.formIntro">
      <h2 v-if="entry.formTitle">{{entry.formTitle}}</h2>
      <wysiwyg v-if="entry.formIntro" :data="entry.formIntro"></wysiwyg>

      <template v-slot:secondary class="inset">
        <question-form class="inset"></question-form>
      </template>
    </content-block>


    <content-block class="grey" :inset-secondary="!isXs" v-if="entry.titleC || entry.infoAccordion.length">
      <h2>{{entry.titleC}}</h2>
      <template v-slot:secondary v-if="entry.infoAccordion.length">
        <div class="accordion-group-title blue">
          Answers to your questions <span @click="expandAll(uids.length-1)">Show / Hide All</span>
        </div>
        <accordion-item v-for="(answer,index) in entry.infoAccordion" :groupUid="uids[uids.length-1]">
          <template v-slot:title>
            {{answer.informationTitle}}
          </template>
          <template v-slot:content>
            <wysiwyg :data="answer.informationContent" :lightbox="true"></wysiwyg>
          </template>
        </accordion-item>
      </template>
    </content-block>

   
  </div>
</template>


<script>
  import HeroImage from "~/components/HeroImage.vue";
  import ContentBlock from "~/components/ContentBlock.vue";
  import Wysiwyg from "~/components/Wysiwyg.vue";
  import AccordionItem from "~/components/AccordionItem.vue";
  import QuestionForm from "~/components/QuestionForm.vue";
  import { EventBus } from '/event-bus.js';
  import gql from 'graphql-tag'

  export default {
    components : { HeroImage, ContentBlock, AccordionItem, QuestionForm, Wysiwyg },
    data(){
      return {
        entry: Object,
        accordionStatus: []
      }
    },
    apollo: {
      page: {
        query: gql`query{page: entry(section: "faqs"){
                  title,
                  slug, sectionHandle
                  ...on faqs_faqs_Entry{
                    heroImage{
                      url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                    titleA: faqsTitleA
                    blurbA: faqsBlurb
                    titleB: faqsTitleB
                    questionsAnswers{
                      ...on questionsAnswers_questionAnswers_BlockType{
                        sectionTitle
                        SectionQuestions{
                          ...on SectionQuestions_BlockType{
                            question
                            answer
                          }
                        }
                      }
                    }
                    formTitle
                    formIntro
                  titleC: faqsTitleC
                  infoAccordion{
                    ...on infoAccordion_informationItem_BlockType{
                      informationTitle
                      informationContent
                    }
                  }
                }
              }          
            }
          `,
        result ({ data, loading, networkStatus }) {
          if (typeof(data) !== 'undefined'){
            if (data.page !== null){ 
              // console.log(data.page)
              this.entry = data.page
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'errorContent' })
            }
          }
          else{
            this.$router.push({ name: 'errorServer' })
          }
        }
      },
    },
    computed: {
      uids() {
        let result = []
        let status = []
        let count = this.entry.questionsAnswers.length + 1 // + 1 to account for questions group
        
        for (let i = 0; i < count; i++) {
          result.push(Date.now() + Math.random())
          status.push(false)
        }

        this.accordionStatus = status
        return result
      },
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      }
    },
    methods: {
      expandAll(index){
        let uid = this.uids[index]
        this.accordionStatus[index] = !this.accordionStatus[index]
        EventBus.$emit('expandAll', {uid: uid, status: this.accordionStatus[index]});
      },
    }
  }
</script>