<template>
  <div id="error500">
    <div class="container">
      <h2>Error.</h2>
      <h3>We are having some temporary problems with our website right now, please check back later.</h3>
    </div>
  </div>
</template>


<script>

  export default {
    data(){
      return {
      }
    },
    computed: {

    }
  }
</script>