<template>
  <form-helper :submit="'Submit'" :recaptcha="true">
    <input type="hidden" name="form_id" value="1">
    <span class="label">Your Details</span>
    <input type="text"
           name="name"
           placeholder="Name" v-model="name">
    <input type="text"
           name="email"
           placeholder="Email" v-model="email">
    <span class="label">Participant Details</span>
    <input type="text"
           name="number_of_participants"
           placeholder="Number of participants" v-model="number_of_participants">
    <textarea name="participant_names" placeholder="Participant names" v-model="participant_names"></textarea>
    <span class="label">Preferred tour date and time</span>
    <span class="note">Tues / Thur / Saturday – By arrangement, 10 participants required</span>
    <input type="text"
           name="preferred_date"
           placeholder="DD / MM / YYYY" v-model="preferred_date">
    <div class="select-wrapper">
      <select name="preferred_time" @change="fauxPlaceholder" v-model="preferred_time">
        <option value="" disabled selected>Choose a time</option>
        <option value="" disabled>-</option>
        <option value="3pm">3pm</option>
        <option value="4pm">4pm</option>
        <option value="Saturday">Saturday - by arrangement</option>
      </select>
    </div>
  </form-helper>
</template>


<script>
  import FormHelper from '~/components/FormHelper'

    export default {
      components: { FormHelper },
      data () {
        return {
          name: null,
          email: null,
          number_of_participants: null,
          participant_names: null,
          preferred_date: null,
          preferred_time: '',
          form_id: 1,
        }
      },
      methods: {
        fauxPlaceholder(e) {
          if(e.target.value !== '') {
            e.target.classList.add('changed')
          }
        }
      }
    }
</script>