<template>
  <div v-if="src" class="hero-image is-cover">
    <craft-image-helper :src="src"></craft-image-helper>
  </div>
</template>


<script>

  export default {
    props: {
      src : {
        type: Array,
        required: true
      }
    },
    data(){
      return {
      }
    }
  }
</script>