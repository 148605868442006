<template>
  <div id="home" v-if="loaded">

    <div class="hero">
      <template v-if="!showVideo">
        <div class="img-wrapper is-cover">
          <craft-image-helper :src="entry.heroImage"></craft-image-helper>
        </div>
        <div class="container">
          <h1><span class="v-strong">{{entry.heroTitle}}</span><template v-if="entry.heroByline"><br>{{entry.heroByline}}</template></h1>
          <span v-if="entry.vimeoLink" class="play" @click="showVideo = true"></span>
        </div>
      </template>

      <template v-else>
        <div class="img-wrapper is-cover">
          <iframe 
            :src="entry.vimeoLink + '?autoplay=true'" 
            frameborder="0" 
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        </div>
      </template>
      
    </div>


    <content-block class="blue has-overlay" v-if="entry.titleA || entry.blurbA">
      <h2 v-if="entry.titleA">{{entry.titleA}}</h2>
      <wysiwyg v-if="entry.blurbA" :data="entry.blurbA"></wysiwyg>
    </content-block>

    

    <div class="divider-image is-cover" v-if="entry.imageA.length">
      <craft-image-helper :src="entry.imageA"></craft-image-helper>
    </div>


    <content-block>
      <h2>{{entry.titleB}}</h2>
      <wysiwyg :data="entry.blurbB"></wysiwyg>
      <template v-slot:secondary v-if="hasKeyPointsB">
        <div class="circles" v-observe-visibility="{callback: visibilityChanged, once: true }">
          <ul :class="'count-' + hasKeyPointsB">
            <li v-if="entry.keyPointB1"><span>{{entry.keyPointB1}}</span></li>
            <li v-if="entry.keyPointB2"><span>{{entry.keyPointB2}}</span></li>
            <li v-if="entry.keyPointB3"><span>{{entry.keyPointB3}}</span></li>
          </ul>
        </div>
      </template>
    </content-block>


    <content-block class="yellow" v-if="entry.titleC || entry.blurbC" :inset-secondary="true">
      <h2 v-if="entry.titleC">{{entry.titleC}}</h2>
      <wysiwyg v-if="entry.blurbC" :data="entry.blurbC"></wysiwyg>

      <template v-slot:secondary>
        <div class="aerial-map">
          <inline-svg :src="$getImageSrc('map-overlay-west')" @click.native="overlayClick($event)"></inline-svg>

          <image-helper :src="'aerial'"></image-helper>
        </div>
      </template>
     
    </content-block>

    <content-block class="white" v-if="entry.titleD || entry.blurbD || entry.titleD2">
      <h2 v-if="entry.titleD">{{entry.titleD}}</h2>
      <wysiwyg v-if="entry.blurbD" :data="entry.blurbD"></wysiwyg>
      <template v-slot:secondary v-if="hasKeyPointsD || entry.titleD2">
        <h3 v-if="entry.titleD2" class="center">{{entry.titleD2}}</h3>
        <div v-if="hasKeyPointsD" class="circles" v-observe-visibility="{callback: visibilityChanged, once: true }">
          <ul :class="'count-' + hasKeyPointsD">
            <li v-if="entry.keyPointD1"><span>{{entry.keyPointD1}}</span></li>
            <li v-if="entry.keyPointD2"><span>{{entry.keyPointD2}}</span></li>
            <li v-if="entry.keyPointD3"><span>{{entry.keyPointD3}}</span></li>
            <li v-if="entry.keyPointD4"><span>{{entry.keyPointD4}}</span></li>
          </ul>
        </div>
      </template>
    </content-block>


    <div class="divider-image is-cover" v-if="entry.imageD.length">
      <craft-image-helper :src="entry.imageD"></craft-image-helper>
    </div>


    <!-- <content-block v-if="newsArticles.length">
      <h2>Check out the latest updates on our blog</h2>
      <template v-slot:secondary>
        <article-grid :articles="newsArticles"></article-grid>
      </template>
    </content-block> -->


    <!-- accordion -->

    <content-block class="yellow accordion" :inset-secondary="!isXs">
      <h2>{{entry.titleF}}</h2>
      <wysiwyg v-if="entry.blurbF" :data="entry.blurbF"></wysiwyg>
      
      <template v-slot:secondary v-if="entry.infoAccordion.length">
        <div class="accordion-group-title">
          <span @click="expandAll(uids.length-1)">Show / Hide All</span>
        </div>
        <accordion-item v-for="(accordion,index) in entry.infoAccordion" :groupUid="uids[uids.length-1]">
          <template v-slot:title>
            {{accordion.question}}
          </template>
          <template v-slot:content>
            <wysiwyg :data="accordion.answer" :lightbox="true"></wysiwyg>
          </template>
        </accordion-item>
      </template>
    </content-block>


    <template v-if="entry.getInTouchTitle">
      <content-block class="content-block white">
        <h2 v-if="entry.getInTouchTitle">{{entry.getInTouchTitle}}</h2>
        <div class="block-links">
          <router-link :to="{name: 'faqs'}">Ask us a question ></router-link>
          <router-link :to="{name: 'contact'}">Contact us ></router-link>
        </div>
      </content-block>
    </template>
    
     <div class="divider-image is-cover" v-if="entry.imageF.length">
      <craft-image-helper :src="entry.imageF"></craft-image-helper>
    </div>
    
  </div>
</template>

<script>
  import ContentBlock from "~/components/ContentBlock.vue";
  import Wysiwyg from "~/components/Wysiwyg.vue";
  import 'vue-twentytwenty/dist/vue-twentytwenty.css';
  import TwentyTwenty from 'vue-twentytwenty';
  import ArticleGrid from "~/components/ArticleGrid.vue";
  import EnquiryForm from "~/components/EnquiryForm.vue";
  import AccordionItem from "~/components/AccordionItem.vue";
  import ImageHelper from "~/components/ImageHelper.vue";
  import { EventBus } from '/event-bus.js';
  import gql from 'graphql-tag'

  export default {
    components : { ContentBlock, Wysiwyg, TwentyTwenty, EnquiryForm, ArticleGrid, AccordionItem, ImageHelper },
    data() {
      return {
        entry: Object,
        newsArticles: Object,
        showVideo: false,
        accordionStatus: [],
        dummyAccordion: [
          {
            "question": "Why does the Port need to grow?",
            "answer": "<p>To support the economic growth of Northland, and North Auckland, we need to increase our freight\nhandling capacity, offer more diverse freight services and adapt to changing freight needs. To do this,\nwe need longer wharves and more space to store and handle cargo. We are also allowing for a shipyard\nfacility with a floating dry dock. If this gets built it would provide as many as 400 unskilled, semi-skilled and high-skilled jobs in the area.\n</p>",
          },
          {
            "question": "Why a commercial shipyard?",
            "answer": "<p>Central Government have been looking at the need for a commercial shipyard and floating dry-dock in\nNew Zealand that could be operated in conjunction with the Navy’s needs. Currently the larger Navy\nvessels, Interisland ferries and domestic cargo vessels must travel overseas (largely to Asia) to undertake\nship maintenance. Northport’s Future Footprint is well able to accommodate a shipyard and floating dry-\ndock, which would generate significant regional employment and economic opportunities for Northland.\n</p>\n<p>While no decision has been made by the Government about the shipyard and dry-dock we remain\nsupportive of the project. We want to secure the opportunity for Northland should the Government or\nany other parties wish to proceed with the project.\n</p>",
          },
          {
            "question": "How much would this all cost to build?",
            "answer": "<p>It is too early to put an accurate figure on the construction cost. What we do know is that\ninfrastructure at this scale is expensive, which is why as a business we need to start planning for it well\nbefore it is needed.\n</p>",
          },
          {
            "question": "Are you going to do everything from your Vision, will it happen right away?",
            "answer": "<p>The Vision for Growth sets out a Future Footprint of the port which will allow the port to grow to match\nthe freight demands of Northland and Northern Auckland over the next 30 to 40 years.\n</p>\n<p>We are a critical infrastructure owner and we forecast freight demand as accurately as we can. This\nenables us to build our new infrastructure in stages so we can continue to support the regional\neconomy and play our part in securing Northland’s economic future\n</p>\n<p>The timing and progress of the shipyard is mostly dependent on decisions and funding from the\nCentral Government.\n</p>",
          },
          {
            "question": "What role will Tangata Whenua have in deciding what happens?",
            "answer": "<p>Tangata whenua are crucial partners in any growth process. We acknowledge Patuharakeke as ahi kā\nand we will do all we can to ensure that we involve Patuharakeke, and other hapū with an interest in\nWhangārei-te-rerenga-parāoa, in every stage of Northport’s growth.\n</p>",
          },
          {
            "question": "Why doesn’t your Vision include railway infrastructure outside the Port?",
            "answer": "<p>This is not infrastructure that Northport Ltd would be responsible for building. We are entirely\ndependent on the government and KiwiRail putting this in place. Our Vision for Growth does, however,\nanticipate that rail transport would be an option for Northport sometime in the future.\n</p>",
          },
          {
            "question": "Won’t you need rail before you can build or expand?",
            "answer": "<p>Not necessarily. Much will depend on what importers and exporters decide they want to ship through\nNorthport, and how. Our Vision allows for a rail siding within the port to allow freight to be moved by\nrail to and from the port.\n</p>",
          }
        ],
      }
    },
    apollo: {
      page: {
        query: gql`query{
          page: entry(section: "home"){
                  title,
                  slug, sectionHandle
                  ...on home_home_Entry{
                    heroTitle
                    heroByline
                    heroImage{
                       url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                    vimeoLink: vimeoVideoUrl
                    titleA: infrastructureTitle
                    blurbA: infrastructureBlurb
                    imageA: homeImageA{
                       url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                    titleB: demandTitle
                    blurbB: demandBlurb
                    keyPointB1: demandKeyPoint1
                    keyPointB2: demandKeyPoint2
                    keyPointB3: demandKeyPoint3
                    titleC: proposingTitle
                    blurbC: proposingBlurb
                    titleD: communitiesTitle
                    blurbD: communitiesBlurb
                    titleD2: jobCreationTitle
                    keyPointD1: communitiesKeyPoint1
                    keyPointD2: communitiesKeyPoint2
                    keyPointD3: communitiesKeyPoint3
                    keyPointD4: communitiesKeyPoint4
                    imageD: homeImageB{
                       url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                    imageF: homeImageC{
                       url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                    titleF: listeningTitle
                    blurbF: listeningBlurb
                    getInTouchTitle
                    infoAccordion{
                      ...on infoAccordion_informationItem_BlockType{
                        question: informationTitle
                        answer: informationContent
                      }
                    }
                  }
                }
                articles: entries(section: "articles", orderBy: "postDate DESC", limit: 3){
                  id, title, slug, sectionHandle
                  publishDate: postDate @formatDateTime (format: "l j F", timezone: "Pacific/Auckland")
                  ...on articles_articles_Entry{
                    heroImage{
                      url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                  } 
                }        
              }
          `,
        result ({ data, loading, networkStatus }) {
          if (typeof(data) !== 'undefined'){
            if (data.page !== null){ 
              // console.log(data.page)
              this.entry = data.page
              this.newsArticles = data.articles
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'errorContent' })
            }
          }
          else{
            this.$router.push({ name: 'errorServer' })
          }
        }
      },
    },
    computed: {
      hasKeyPointsB() {
        let result = []
        let e = this.entry
        if(e.keyPointB1) {
          result.push(e.keyPointB1)
        }
        if(e.keyPointB2) {
          result.push(e.keyPointB2)
        }
        if(e.keyPointB3) {
          result.push(e.keyPointB3)
        }
        return result.length
      },
      hasKeyPointsD() {
        let result = []
        let e = this.entry
        if(e.keyPointD1) {
          result.push(e.keyPointD1)
        }
        if(e.keyPointD2) {
          result.push(e.keyPointD2)
        }
        if(e.keyPointD3) {
          result.push(e.keyPointD3)
        }
        if(e.keyPointD4) {
          result.push(e.keyPointD4)
        }
        return result.length
      },
      uids() {
        let result = []
        let status = []
        let count = this.dummyAccordion.length + 1 // + 1 to account for questions group
        
        for (let i = 0; i < count; i++) {
          result.push(Date.now() + Math.random())
          status.push(false)
        }

        this.accordionStatus = status
        return result
      },
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      }
    },
    methods: {
      visibilityChanged (isVisible, entry) {
        if(isVisible) {
          entry.target.classList.add('visible')
        }
      },
      overlayClick(event) {
        if (event.target.id === "eastern-trigger") {
          this.$router.push({name : 'details'})
        }
        // console.log(event)
      },
      expandAll(index){
        let uid = this.uids[index]
        this.accordionStatus[index] = !this.accordionStatus[index]
        EventBus.$emit('expandAll', {uid: uid, status: this.accordionStatus[index]});
      },
      
    }
  }
</script>