<template>
  <div id="submissions" v-if="loaded">

    <hero-image v-if="entry.heroImage.length" :src="entry.heroImage"></hero-image>

    <content-block class="yellow documents" v-if="entry.voiceHeading || entry.voiceHeading">
      <h2 v-if="entry.voiceHeading">{{entry.voiceHeading}}</h2>
      <wysiwyg v-if="entry.voiceBlurb" :data="entry.voiceBlurb"></wysiwyg>

      <div class="hero-doc">
        <craft-image-helper v-if="entry.voiceDocument1[0].thumbnail.length" :src="entry.voiceDocument1[0].thumbnail"></craft-image-helper>
        
        <ul>
          <li>Download the document</li>
          <li>{{entry.voiceDocument1[0].title}}</li>
          <li v-if="entry.voiceDocument1[0].documentByline" class="description">{{entry.voiceDocument1[0].documentByline}}</li>
          <li><a :href="entry.voiceDocument1[0].url" target="_blank" class="is-download">Download ({{$getSize(entry.voiceDocument1[0].size)}})</a></li>
        </ul>
      </div>

      <div class="secondary-docs">
        <h2 v-if="entry.voiceHeadingB">{{entry.voiceHeadingB}}</h2>
        <wysiwyg v-if="entry.voiceBlurb2" :data="entry.voiceBlurb2"></wysiwyg>
        
        <div class="doc" v-for="(document,index) in entry.submissionDocuments">
          <p><strong>{{document.submissionDocument[0].title}}</strong></p>
          <a :href="document.submissionDocument[0].url" class="is-download">Download ({{$getSize(document.submissionDocument[0].size)}})</a>
          <wysiwyg v-if="document.submissionDocDetails" :data="document.submissionDocDetails"></wysiwyg>
        </div>
      </div>

    </content-block>

  </div>
</template>

<script>
  import HeroImage from "~/components/HeroImage.vue";
  import ContentBlock from "~/components/ContentBlock.vue";
  import Wysiwyg from "~/components/Wysiwyg.vue"; 
  import gql from 'graphql-tag'

  export default {
    components: { HeroImage, ContentBlock, Wysiwyg },
    data() {
      return {
        entry: Object,
      }
    },
    apollo: {
      page: {
        query: gql`query{
          page: entry(section: "submissions"){
                  title,
                  slug, sectionHandle
                  ...on submissions_submissions_Entry{
                    heroTitle
                    heroByline
                    heroImage{
                       url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                    voiceHeading
                    voiceBlurb
                    voiceDocument1 {
                      url, title, size
                          ...on documents_Asset {
                            documentByline
                            thumbnail {
                              url,
                              title,
                              width,
                              height,
                              focalPoint,
                              mimeType,
                              s400: url @transform(width: 400),
                              s800: url @transform(width: 800),
                              s1200: url @transform(width: 1200),
                              s1600: url @transform(width: 1600),
                              s2000: url @transform(width: 2000),
                              s2400: url @transform(width: 2400),
                              s2800: url @transform(width: 2800),
                              s3200: url @transform(width: 3200)
                            }
                          }
                        
                    }
                    voiceHeadingB
                    voiceBlurb2
                    submissionDocuments {
                      ...on submissionDocuments_submissionDocuments_BlockType{
                        submissionDocument{
                          url, title, size
                          ...on documents_Asset {
                            documentByline
                            }
                         }
                        submissionDocDetails
                      }
                    }
                  }

                }      
              }
          `,
        result ({ data, loading, networkStatus }) {
          if (typeof(data) !== 'undefined'){
            if (data.page !== null){ 
              // console.log(data.page)
              this.entry = data.page
              this.newsArticles = data.articles
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'errorContent' })
            }
          }
          else{
            this.$router.push({ name: 'errorServer' })
          }
        }
      },
    },
    computed: {
    },
    methods: {
    }
  }
</script>