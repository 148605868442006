<template>
  <div class="impacts-grid">
    <router-link 
      class="teaser"
      v-for="(impact, index) in impacts" 
      :to="{name: 'impactArticle', params: {slug: impact.slug}}">
      <span :style="{backgroundColor : impact.backgroundColor}">{{impact.title}}</span>
      <div class="is-cover sixteen-nine">
        <craft-image-helper :src="impact.heroImage"></craft-image-helper>
      </div>
    </router-link>

  </div>
</template>


<script>
  export default {
    props: {
      impacts: {
        type: Array,
        required: true
      }
    },
    data(){
      return {
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>