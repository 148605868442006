<template>
  <div id="east" v-if="loaded">

    <hero-image :src="entry.heroImage"></hero-image>

    <content-block class="blue has-overlay" v-if="entry.titleA || entry.blurbA">
      <h2 v-if="entry.titleA">{{entry.titleA}}</h2>
      <wysiwyg v-if="entry.blurbA" :data="entry.blurbA"></wysiwyg>
    </content-block>

    <content-block class="white big-gap" v-if="entry.titleB || entry.blurbB">
      <h2 v-if="entry.titleB">{{entry.titleB}}</h2>
      <wysiwyg v-if="entry.blurbB" :data="entry.blurbB"></wysiwyg>

      <template v-slot:misc>
       
        <div class="divider-image" v-if="entry.purposeImage.length">
          <craft-image-helper :src="entry.purposeImage"></craft-image-helper>
        </div>

        <div class="col inset" v-if="entry.titleC || entry.blurbC">
          <h2 v-if="entry.titleC">{{entry.titleC}}</h2>
          <wysiwyg v-if="entry.blurbC" :data="entry.blurbC"></wysiwyg>
        </div>

        <div class="col inset" v-if="entry.aerialAfter[0] && entry.aerialBefore[0]">
          <TwentyTwenty
          class="compare"
          :before="entry.aerialAfter[0].s2800"
          :after="entry.aerialBefore[0].s2800"
          :offset="0.12">
          </TwentyTwenty>
        </div>

        <div class="col inset" v-if="entry.titleD || entry.blurbD">
          <h2 v-if="entry.titleD">{{entry.titleD}}</h2>
          <wysiwyg v-if="entry.blurbD" :data="entry.blurbD"></wysiwyg>
        </div>

        <div class="col">
          <h3 class="strong center">Completed Stages</h3>
          <ul class="process completed" v-observe-visibility="{callback: visibilityChanged, once: true }">
            <li v-for="(stage,preCounter) in entry.stages" :data-count="preCounter">
              <p class="small"><span>{{stage.dateRange}}</span>{{stage.blurb}}</p>
            </li>
            <!--<span class="col grid" :class="stageClasses">
              <li v-for="(stage,activeCounter) in activeStages" :data-count="activeCounter+preStages.length">{{stage.blurb}}</li>
            </span>
            <li v-for="(stage,postCounter) in postStages" :data-count="postCounter+preStages.length+activeStages.length">{{stage.blurb}}</li>-->
          </ul>
          <h3 class="strong center">Next Stages</h3>
          <ul class="process yellow next" v-observe-visibility="{callback: visibilityChanged, once: true }">
            <div></div>
            <li v-for="(stage,preCounter) in entry.nextStages" :data-count="preCounter + entry.stages.length">
              <p class="small"><span>{{stage.nextStageDateRange}}</span>{{stage.nextStageBlurb}}</p>
            </li>
            <!--<span class="col grid" :class="stageClasses">
              <li v-for="(stage,activeCounter) in entry.nextStages" :data-count="activeCounter+preStages.length">{{stage.blurb}}</li>
            </span>
            <li v-for="(stage,postCounter) in entry.nextStages" :data-count="postCounter+preStages.length+activeStages.length">{{stage.blurb}}</li>-->
          </ul>
        </div>

      </template>
    </content-block>

  </div>
</template>

<script>
  import ContentBlock from "~/components/ContentBlock.vue";
  import HeroImage from "~/components/HeroImage.vue";
  import Wysiwyg from "~/components/Wysiwyg.vue";
  import 'vue-twentytwenty/dist/vue-twentytwenty.css';
  import TwentyTwenty from 'vue-twentytwenty';
  import VideoHelper from '~/components/VideoHelper.vue';
  import gql from 'graphql-tag'

  export default {
    components : { ContentBlock, HeroImage, Wysiwyg, TwentyTwenty, VideoHelper },
    data() {
      return {
        entry: Object,
        preStages: [],
        activeStages: [],
        postStages: [],
      }
    },
    apollo: {
      page: {
        query: gql`query{
          page: entry(section: "details"){
                  title,
                  slug, sectionHandle
                  ...on details_details_Entry{
                    heroImage{
                       url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                    titleA: developmentHeading
                    blurbA: developmentBlurb
                    titleB: purposeHeading
                    blurbB: purposeBlurb
                    purposeImage{
                       url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                    titleC: processHeading
                    blurbC: processBlurb
                    aerialBefore{
                       url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                    aerialAfter{
                       url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                    titleD: stageHeading
                    blurbD: stageBlurb
                    stages{
                      ...on stages_stage_BlockType{
                        dateRange
                        blurb
                      }
                    }
                    nextStages{
                     ...on nextStages_nextStage_BlockType{
                        nextStageDateRange
                        nextStageBlurb
                      }
                    }
                  }
                }      
              }
          `,
          fetchPolicy: 'no-cache',
        result ({ data, loading, networkStatus }) {
          // console.log('GraphQL results:')
          // console.log(data.page)
          if (typeof(data) !== 'undefined'){
            if (data.page !== null){ 
              // console.log(data.page)
              this.entry = data.page

              // split stages
              let preStages = []
              let activeStages = []
              let postStages =[]

              // find active
              // let activeInProgress = false
              // let activePassed = false
              // data.page.stages.forEach(function (value,index) {
              //   // console.log('index: ' + index)
              //   // console.log('start: ' + data.page.stageFrom)
              //   // console.log('end: ' + data.page.stageTo)
              //   if(data.page.stageFrom > data.page.stageTo) {
              //     data.page.stageTo = data.page.stageFrom
              //   }

              //   if (index >= data.page.stageFrom && index <= data.page.stageTo){
              //     // start of active
              //     activeInProgress = true
              //     if (!activeStages.includes(value)){
              //       activeStages.push(value)
              //     }
              //   }
              //   if (index <= data.page.stageTo && index >= data.page.stageFrom){
              //     // end of active
              //     if (!activeStages.includes(value)){
              //       activeStages.push(value)
              //     }
                  
              //     activeInProgress = false
              //     activePassed = true
              //   }
              //   // the rest
              //   if (index < data.page.stageFrom){
              //     //console.log('pre set add')
              //     preStages.push(value)
              //   }
              //   if (index > data.page.stageTo){
              //     //console.log('post set add')
              //     postStages.push(value)
              //   }
              // })

              // this.preStages = preStages
              // this.activeStages = activeStages
              // this.postStages = postStages
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              console.log('error content')
              this.$router.push({ name: 'errorContent' })
            }
          }
          else{
            console.log('error server')
            this.$router.push({ name: 'errorServer' })
          }
        }
      },
    },
    computed: {
      stageClasses() {
        let count = this.activeStages.length
        return [`md-span-${count}-col`, `grid md-col-${count}`]
      },
      hasStages() {
        return (this.preStages.length || this.activeStages.length || this.postStages.length)
      },
    },
    methods: {
      visibilityChanged (isVisible, entry) {
        if(isVisible) {
          entry.target.classList.add('visible')
        }
      },
    }
  }
</script>