<template>
  <div id="impact-article" v-if="loaded">
    
    <hero-image :src="entry.heroImage"></hero-image>

    <content-block class="custom has-overlay">
      <h2>{{entry.titleA}}</h2>
      <wysiwyg :data="entry.blurbA"></wysiwyg>
    </content-block>


    <content-block class="grey" :inset-secondary="!isXs" v-if="entry.infoAccordion.length || entry.titleB || hasDocLink">
      <h2 v-if="entry.titleB">{{entry.titleB}}</h2>
      <template v-if="entry.infoAccordion.length || hasDocLink" v-slot:secondary>
        <template v-if="entry.infoAccordion.length">
          <p class="show-hide"><span @click="expandAll()">Show / Hide All</span></p>
          <accordion-item v-for="(item, index) in entry.infoAccordion" :groupUid="accordionUid">
            <template v-slot:title>
              {{item.informationTitle}}
            </template>
            <template v-slot:content>
              <wysiwyg :data="item.informationContent" :lightbox="true"></wysiwyg>
            </template>
          </accordion-item>
        </template>
        <a v-if="hasDocLink" class="doc-link" :href="entry.docLink[0].url" target="_blank">Download the information sheet</a>
      </template>
    </content-block>
  

    <content-block class="white" :inset-secondary="true" v-if="filteredImpacts.length">
      <h2>Other areas of investigation</h2>
      <p>Click on the links below to find out more</p>
      <template v-slot:secondary>
        <impacts-grid :impacts="filteredImpacts"></impacts-grid>
      </template>
    </content-block>


    <content-block class="grey" v-if="impactsOverviewEntry.titleC || impactsOverviewEntry.blurbC">
      <h2 v-if="impactsOverviewEntry.titleC">{{impactsOverviewEntry.titleC}}</h2>
      <wysiwyg v-if="impactsOverviewEntry.blurbC" :data="impactsOverviewEntry.blurbC"></wysiwyg>
    </content-block>

  </div>
</template>


<script>
  import HeroImage from "~/components/HeroImage.vue";
  import ContentBlock from "~/components/ContentBlock.vue"
  import Wysiwyg from "~/components/Wysiwyg.vue";
  import AccordionItem from "~/components/AccordionItem.vue";
  import ImpactsGrid from "~/components/ImpactsGrid.vue";
  import { EventBus } from '/event-bus.js';
  import gql from 'graphql-tag'

  export default {
    components: { HeroImage, ContentBlock, Wysiwyg, AccordionItem, ImpactsGrid },
    data(){
      return {
        entry: Object,
        impactsOverviewEntry: Object,
        impactsAll: Object,
        accordionUid: Date.now() + Math.random(),
        accordionStatus: false
      }
    },
    apollo: {
      page: {
        query: gql`query variables($slug: [String!]){
          page: entry(slug: $slug){
                  title,
                  slug, sectionHandle
                  ...on impactPages_impactPages_Entry{
                    heroImage{
                      url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                    docLink: impactDoc{
                      url
                    }
                    backgroundColor 
                    textColor 
                    titleA: impactsTitleA
                    blurbA: impactsBlurbA
                    titleB: impactsTitleB
                    infoAccordion{
                    ...on infoAccordion_informationItem_BlockType{
                      informationTitle
                      informationContent
                    }
                  }
                }
              
            }
            impactsOverview: entry(section: "impacts"){
              ...on impacts_impacts_Entry{
                titleC: impactsTitleC
                blurbC: impactsBlurbC
              }
            }
            impactsAll: entries(section: "impactPages"){
              id, title, slug, sectionHandle ...on impactPages_impactPages_Entry{
                backgroundColor
                docLink: impactDoc{
                      url
                    }
                heroImage{
                  url,
                      title,
                      width,
                      height,
                      focalPoint,
                      mimeType,
                      s400: url @transform(width: 400),
                      s800: url @transform(width: 800),
                      s1200: url @transform(width: 1200),
                      s1600: url @transform(width: 1600),
                      s2000: url @transform(width: 2000),
                      s2400: url @transform(width: 2400),
                      s2800: url @transform(width: 2800),
                      s3200: url @transform(width: 3200)
                }
              }
            }
          }
          `,
          variables() {      
            return {slug: this.$route.params.slug}
          },
        result ({ data, loading, networkStatus }) {
          if (typeof(data) !== 'undefined'){
            if (data.page !== null){ 
              // console.log(data.page)
              this.entry = data.page
              this.impactsOverviewEntry = data.impactsOverview
              this.impactsAll = data.impactsAll
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
              this.updateColors()
            }
            else{
              this.$router.push({ name: 'errorContent' })
            }
          }
          else{
            this.$router.push({ name: 'errorServer' })
          }
        }
      },
    },
    computed: {
      filteredImpacts(){ 
        let result = []
        let self = this
        this.impactsAll.forEach(function (value, index) {
          if(value.slug !== self.entry.slug) {
            result.push(value)
          }
        });
        return result
      },
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      hasDocLink() {
        return (this.entry.docLink && this.entry.docLink.length)
      }
    },
    methods: {
      expandAll(){
        this.accordionStatus = !this.accordionStatus
        EventBus.$emit('expandAll', {uid: this.accordionUid, status: this.accordionStatus});
      },
      updateColors() {
        document.documentElement.style.setProperty('--color', this.entry.backgroundColor);
        document.documentElement.style.setProperty('--text-color', this.entry.textColor);
      }
    }
  }
</script>