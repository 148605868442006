<template>
  <form-helper :submit="'Submit'" :recaptcha="true">
    <input type="hidden" name="form_id" value="3">
    <span class="label">Your Details</span>
    <input type="text"
           name="name"
           placeholder="Name" v-model="name">
    <input type="text"
           name="email"
           placeholder="Email" v-model="email">
    <span class="label">What woud you like to know?</span>
    <textarea name="question" placeholder="Your question" v-model="question"></textarea>
  </form-helper>
</template>


<script>
  import FormHelper from '~/components/FormHelper'

    export default {
      components: { FormHelper },
      data () {
        return {
          name: null,
          email: null,
          question: '',
        }
      }
    }
</script>