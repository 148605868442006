<template>
  <form-helper :submit="'Submit'" :recaptcha="true">
    <input type="hidden" name="form_id" value="2">
    <input type="text"
           name="name"
           placeholder="Name" v-model="name">
    <input type="text"
           name="email"
           placeholder="Email" v-model="email">
  </form-helper>
</template>


<script>
  import FormHelper from '~/components/FormHelper'

    export default {
      components: { FormHelper },
      data () {
        return {
          name: null,
          email: null,
          form_id: 2,
        }
      },
    }
</script>