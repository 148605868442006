<template>
  <div id="documents" v-if="loaded">

    <hero-image :src="entry.heroImage"></hero-image>

    <content-block class="blue">
      <h2>{{entry.titleA}}</h2>
      <wysiwyg :data="entry.blurbA"></wysiwyg>
      <div class="notice">
        <p>The Hearing into Northport’s Resource Consent Application concluded on Tuesday 4 June 2024. See the <router-link :to="{name: 'details'}">details page</router-link> for more information about the next steps.</p>
      </div>
    </content-block>

    <template v-if="entry.documentDownloads">
      <content-block v-for="(documentSection, index) in entry.documentDownloads" class="white has-border" :inset-secondary="true">
        <div>
          <h2 v-if="documentSection.title">{{documentSection.title}}</h2>
          <p v-if="documentSection.description" class="group-description">{{documentSection.description}}</p>
        </div>
        <template v-slot:secondary v-if="documentSection.documents.length">
          <div class="docs-wrapper">
            
            <div v-for="(document,index) in documentSection.documents" class="doc" >
              <div class="img-wrapper">
                <craft-image-helper v-if="document.thumbnail.length" :src="document.thumbnail"></craft-image-helper>
              </div>
              <ul>
                <li class="title">{{document.title}}</li>
                <li v-if="document.documentByline" class="description">{{document.documentByline}}</li>
                <li><a :href="document.url" target="_blank">Download ({{$getSize(document.size)}})</a></li>
              </ul>
            </div>
            
          </div>
        </template>
      </content-block>
    </template>

    <content-block v-if="entry.titleB || entry.blurbB" class="grey">
      <h2>{{entry.titleB}}</h2>
      <wysiwyg :data="entry.blurbB"></wysiwyg>
    </content-block>

  </div>
</template>

<script>
  import HeroImage from "~/components/HeroImage.vue";
  import ContentBlock from "~/components/ContentBlock.vue";
  import Wysiwyg from "~/components/Wysiwyg.vue";
  import gql from 'graphql-tag'

  export default {
    components: { HeroImage, ContentBlock, Wysiwyg },
    data(){
      return {
        entry: Object
      }
    },
    apollo: {
      page: {
        query: gql`query{
          page: entry(section: "documents"){
                  title,
                  slug, sectionHandle
                  ...on documents_documents_Entry{
                    heroImage{
                      url,
                          title,
                          width,
                          height,
                          focalPoint,
                          mimeType,
                          s400: url @transform(width: 400),
                          s800: url @transform(width: 800),
                          s1200: url @transform(width: 1200),
                          s1600: url @transform(width: 1600),
                          s2000: url @transform(width: 2000),
                          s2400: url @transform(width: 2400),
                          s2800: url @transform(width: 2800),
                          s3200: url @transform(width: 3200)
                    }
                    titleA: documentsTitleA
                    blurbA: documentsBlurbA
                    documentDownloads{
                      ...on documentDownloads_downloadSection_BlockType{
                        title: sectionTitle
                        description: sectionDescription
                        documents {
                          url, title, size
                          ...on documents_Asset {
                            documentByline
                            thumbnail {
                              url,
                              title,
                              width,
                              height,
                              focalPoint,
                              mimeType,
                              s400: url @transform(width: 400),
                              s800: url @transform(width: 800),
                              s1200: url @transform(width: 1200),
                              s1600: url @transform(width: 1600),
                              s2000: url @transform(width: 2000),
                              s2400: url @transform(width: 2400),
                              s2800: url @transform(width: 2800),
                              s3200: url @transform(width: 3200)
                            }
                          }
                        }
                      }
                    }
                    titleB: documentsTitleB
                    blurbB: documentsBlurbB
                  }
                }      
              }
          `,
        result ({ data, loading, networkStatus }) {
          if (typeof(data) !== 'undefined'){
            if (data.page !== null){ 
              // console.log(data.page)
              this.entry = data.page
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'errorContent' })
            }
          }
          else{
            this.$router.push({ name: 'errorServer' })
          }
        }
      },
    },
    computed: {
    }
  }
</script>