<template>
  <div v-if="data" class="wysiwyg" :class="{'has-lightbox' : lightbox}" ref="wysiwyg" v-html="data">
    <slot></slot>
  </div>
</template>


<script>
  const bodyScrollLock = require('body-scroll-lock');

  export default {
    props: {
      data: {
        required: true
      },
      lightbox: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        lightboxActive: false
      }
    },
    methods: {
      dismiss(event) {
        if (this.data && this.lightbox && this.lightboxActive && (event.keyCode === 27 || event.type === 'resize')) { // ESC key was pressed...
          let target = this.$refs.wysiwyg.children

          Object.keys(target).forEach(function (key) {
            if(target[key].nodeName === 'FIGURE') {
              target[key].classList.remove('active');
            }
          });
          this.lightboxActive = !this.lightboxActive
          bodyScrollLock.clearAllBodyScrollLocks();
        }
      }
    },
    mounted(){
      if(this.data) {
        let target = this.$refs.wysiwyg.children
        let self = this

        Object.keys(target).forEach(function (key) {
          if(target[key].nodeName === 'FIGURE') {

            let figure = target[key]

            Object.keys(figure.children).forEach(function (key) {

              if(figure.children[key].nodeName === ('IFRAME')) {
                figure.children[key].parentNode.className += " is-cover sixteen-nine";
              }
            })

            if(self.lightbox) {
              figure.addEventListener('click', event => {
                event.target.classList.toggle('active');
                self.lightboxActive = !self.lightboxActive
                
                if(self.lightboxActive) {
                  bodyScrollLock.disableBodyScroll(event);
                } else {
                  bodyScrollLock.clearAllBodyScrollLocks();
                }
              });

              window.addEventListener('keyup', self.dismiss);
              window.addEventListener('resize', self.dismiss);
            }
          }
        });
      }
    },
    destroyed() {
      window.removeEventListener('keyup', this.dismiss);
      window.removeEventListener('resize', this.dismiss);
    },
  }
</script>