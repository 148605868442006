export default {
  state: {
    loaded: false,
    breakpoint: '',
    fullURL: process.env.BASE_URL,
    craftLoaded: false,
    craftPageData: null,
    test: false
  },
  mutations: {
    setCraftLoaded (state, payload){
      state.craftLoaded = payload
    },
    setCraftPageData (state, payload){
      state.craftPageData = payload
    },
    setLoaded (state, payload){
      state.loaded = payload
    },
    setBreakpoint (state, payload){
      state.breakpoint = payload
    },
    setCSRFTokenName (state, payload){
      state.csrfTokenName = payload
    },
    setCSRFToken (state, payload){
      state.csrfToken = payload
    },
  },
  actions: {
  }
}