<template>
  <div class="accordion-item">
    <div class="accordion-title" :class="{'active' : active}" @click="toggle">
      <slot name="title"></slot>
    </div>
    <div class="accordion-content" ref="content" :class="{'active' : active}" :style="setHeight">
      <div class="accordion-inner">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>


<script>
  import { EventBus } from '/event-bus.js';

  export default {
    props: {
      groupUid: { // Provide to expand / collapse all. 
        type: Number, // Date.now() + Math.random()
        default: 0
      },
      collapseUid: { // Provide if only one drawer to be open at a time. 
        type: Number, // Date.now() + Math.random()
        default: 0
      },
    },
    data(){
      return {
        active: false,
        scrollHeight: null,
        width: null,
        uid: Date.now() + Math.random(),
      }
    },
    computed: {
      setHeight(){
        if(this.active && this.scrollHeight) {
          return { height : this.scrollHeight + 'px'}
        }
      },
    },
    methods: {
      toggle() {
        if(this.$refs.content !== undefined) {
          this.active = !this.active
          this.scrollHeight = this.$refs.content.scrollHeight
          if(this.collapseUid && this.active) {
            EventBus.$emit('accordionChange', {uid: this.uid, collapseUid: this.collapseUid});
          }
        }
      },
      close() {
        if(window.innerWidth !== this.width) {
          this.width = window.innerWidth
          this.active = false
        }
      }
    },
    created() {
      this.width = window.innerWidth
      window.addEventListener('resize',this.close);

      if(this.collapseUid) {
        EventBus.$on('accordionChange', (emit) => {
          if(emit.collapseUid === this.collapseUid && this.uid !== emit.uid) {
            this.active = false
          }
        });
      }

      if(this.groupUid) {
        EventBus.$on('expandAll', (emit) => {
          // console.log('expand all')
          // console.log(emit.status)
          if(this.groupUid === emit.uid) {
            if(!this.active && emit.status) {
              this.toggle()
            } else if(this.active && !emit.status) {
              this.toggle()
            }
          }
        });
      }

      
    },
    destroyed() {
      window.removeEventListener('resize',this.close);
    }
  }
</script>