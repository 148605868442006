<template>
  <header>
    <div class="container">

      <router-link :to="{name: 'home'}" :class="{'disabled' : isHome}" class="logo">
        <img src="/assets/img/northport.svg">
      </router-link>
      
      <nav :class="navClasses">
        <ul>
          <li><router-link :to="{name: 'home'}">Overview</router-link></li>
          <li><router-link :to="{name: 'details'}">Details</router-link></li>
          <li><router-link :to="{name: 'impacts'}">Impacts</router-link></li>
          <!-- <li><router-link :to="{name: 'submissions'}">Submissions</router-link></li> -->
          <li><router-link :to="{name: 'faqs'}">Ask us a question</router-link></li>
          <!-- <li><router-link :to="{name: 'blog'}">Blog</router-link></li> -->
          <li><router-link :to="{name: 'documents'}">Documents</router-link></li>
          <li><router-link :to="{name: 'contact'}">Contact</router-link></li>
        </ul>
        <div class="sign-up" @click="scroll()">Sign up for email updates</div>
      </nav>
      <div v-if="xsMode" @click="openOverlay" class="hamburger" :class="{'active' : overlay}"></div>
    </div>
  </header>
</template>


<script>

  export default {
    data(){
      return {
        overlay: false,
        transition: false,
        scrollPos: null
      }
    },
    computed: {
      breakpoint() {
        return this.$store.state.breakpoint
      },
      isHome() {
        return this.$route.name === 'home'
      },
      showNav() {
        return !this.xsMode || this.overlay
      },
      xsMode() {
        return this.breakpoint === 'xs' || this.breakpoint === 'sm' || this.breakpoint === 'md' || this.breakpoint === 'lg'
      },
      navClasses() {
        let result = []
        if(this.showNav) { 
          result.push('active') 
        }
        if(this.transition) { 
          result.push('transition') 
        }
        if(this.xsMode) { 
          result.push('xs-mode') 
        }
        return result
      },
    },
    methods: {
      openOverlay() {
        if(this.xsMode) {
          this.overlay = !this.overlay
          this.transition = true
          this.scrollPos = (this.overlay) ? window.scrollY : null
          setTimeout(function() {
            this.transition = false
          }, 350);
        }
      },
      checkScroll(){
        let curScroll = window.scrollY
        let offset = 200
        if(this.xsMode && this.overlay) {
          if(curScroll > this.scrollPos + offset || curScroll < this.scrollPos - offset) {
            this.openOverlay()
          }
        }
      },
      scroll() {
        let offset
        switch (this.breakpoint) {
          case 'xs' || 'sm':
            offset = -60
            break
          case 'md':
            offset = -85
            break
          default:
            offset = -100
            break
        }
        this.$scrollTo('#sign-up', 1000, { offset: offset})
        this.openOverlay()
      }
    },
    watch: {
      $route() {
        this.transition = false
        this.overlay = false
      },
      breakpoint() {
        this.transition = false
        this.overlay = false
      }
    },
    created() {
      window.addEventListener('scroll', this.checkScroll);
    },
    destroyed () {
      window.removeEventListener('scroll', this.checkScroll);
    },
  }
</script>